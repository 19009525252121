import { translate } from "react-i18nify"
import { Text } from "@chakra-ui/react"

export const LegalNotice = (props) => {
  const notice = translate(`application.legal_notice_${process.env.REACT_APP_CUSTOM_STYLES}`)

  if (notice === `legal_notice_${process.env.REACT_APP_CUSTOM_STYLES}` || notice === "-") {
    return null
  }

  return (
    <Text fontSize="xs" {...props}>
      <span className="legal-notice" dangerouslySetInnerHTML={{ __html: notice }} />
    </Text>
  )
}

import { ChakraProvider, Flex, Stack } from "@chakra-ui/react"
import * as themes from "themes"
import { Footer, Header } from "layouts"

export const DefaultLayout = ({ children }) => (
  <ChakraProvider theme={themes[process.env.REACT_APP_CUSTOM_STYLES]}>
    <Stack>
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="flex-start"
        width="100%"
        maxWidth="60rem"
        marginX="auto"
        paddingX="1.6rem"
        minHeight="100vh"
      >
        <Header />
        <Stack as="main" width="100%" spacing="1.5rem" pt="2rem" pb="4rem" flexGrow="1">
          {children}
        </Stack>
        <Footer />
      </Flex>
    </Stack>
  </ChakraProvider>
)

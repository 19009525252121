import { constants } from "utils/constants"

export const getLocalStorageItem = (key) => {
  try {
    return localStorage.getItem(key)
  } catch (e) {
    console.error(e)
    return null
  }
}

export const setLocalStorageItem = (key, value) => {
  try {
    localStorage.setItem(key, value)
  } catch (e) {
    console.error(e)
  }
}

export const cleanLocalStorage = (keys = constants.STORAGE_KEYS_TO_CLEAN) => {
  try {
    keys.forEach((key) => {
      localStorage.removeItem(key)
    })
  } catch (e) {
    console.error(e)
  }
}

import { Text } from "@chakra-ui/react"
import _ from "lodash"
import { constants } from "utils/constants"

export const displayHydraError = (
  display,
  error,
  defaultTitle = "Something went wrong",
  status = "error"
) => {
  const title = _.get(error, "response.data.hydra:title", defaultTitle)
  const description = _.truncate(_.get(error, "response.data.hydra:description", error?.message), {
    length: 100,
  })
  display({
    title,
    description: description ? <Text wordBreak="break-word">{description}</Text> : "",
    status,
    duration: constants.TOAST_ERROR_DURATION,
    isClosable: true,
  })
}

import { Box } from "@chakra-ui/react"
import _ from "lodash"
import { getFormattedValue } from "utils"

export const DisplayValue = ({
  entity,
  path = null,
  type = "string",
  label = null,
  defaultValue = "",
  maxTextLength = null,
  format = null,
  unit = null,
}) => {
  const value = _.isObject(entity) && path ? _.get(entity, path, null) : entity
  const formattedValue = _.isFunction(format)
    ? format(value)
    : getFormattedValue(value, type, null, maxTextLength, unit)

  if (_.isNull(formattedValue)) {
    return defaultValue
  }

  return (
    <Box>
      {label}
      {type === "text" ? (
        <span dangerouslySetInnerHTML={{ __html: formattedValue }} />
      ) : (
        formattedValue
      )}
    </Box>
  )
}

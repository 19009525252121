import { extendTheme } from "@chakra-ui/react"
import { mode } from "@chakra-ui/theme-tools"

export const sharemat = extendTheme({
  styles: {
    global: (props) => ({
      body: {
        bg: mode("#f8f9fa", "#0d2033")(props),
        color: mode("#0d2033", "white")(props),
      },
    }),
  },
  components: {
    Accordion: {
      parts: ["button", "icon"],
      baseStyle: {
        button: {
          _hover: {
            bg: "#5475961c",
          },
        },
        icon: {
          color: "#f97303",
          fontSize: "2rem",
        },
      },
    },
    Button: {
      variants: {
        important: {
          color: "white",
          bg: "#f97303",
          _hover: {
            bg: "#ffbf00",
          },
        },
      },
    },
  },
})

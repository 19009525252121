import { localize, translate, Translate } from "react-i18nify"
import { FaRegBell, FaRegCheckCircle } from "react-icons/fa"
import { Flex, Icon } from "@chakra-ui/react"

export const InterventionStatus = ({ equipment, configuration }) => {
  if (!configuration) {
    return null
  }

  return (
    <Flex align="center" mb={2}>
      {configuration.overdue ? (
        <Icon as={FaRegBell} color="red" mr={3} title={translate("common.outdated")} />
      ) : (
        <Icon as={FaRegCheckCircle} color="green" mr={3} />
      )}
      <Translate
        value="equipment.last_date"
        interventionType={configuration.publicInterventionType?.name}
        family={translate(`api.intervention_type.${configuration.publicInterventionType?.family}`)}
        date={
          configuration.lastDate
            ? localize(configuration.lastDate, { dateFormat: "dates.standard" })
            : translate("common.nc")
        }
      />
      <br />
      {configuration.publicInterventionType?.family === "control" && (
        <Translate
          value={`equipment.due_date`}
          interventionType={configuration.publicInterventionType?.name}
          family={translate(
            `api.intervention_type.${configuration.publicInterventionType?.family}`
          )}
          date={
            configuration.dueDate
              ? localize(configuration.dueDate, { dateFormat: "dates.standard" })
              : translate("common.nc")
          }
        />
      )}
    </Flex>
  )
}

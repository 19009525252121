import * as de from "./de.json"
import * as en from "./en.json"
import * as fr from "./fr.json"
import * as nl from "./nl.json"

export const translations = {
  fr,
  en,
  nl,
  de,
}

import { localize, translate } from "react-i18nify"
import _ from "lodash"
import { constants, getLocalStorageItem } from "utils"

const MS_PER_DAY = 1000 * 60 * 60 * 24

export const getDefaultLang = () => {
  const browserLang = navigator?.language || navigator?.userLanguage
  let defaultLang = browserLang ? browserLang.substring(0, 2) : constants.DEFAULT_LANG
  if (!constants.LANGS.includes(defaultLang)) {
    defaultLang = constants.DEFAULT_LANG
  }
  return getLocalStorageItem("lang") ?? defaultLang
}

export const getUrlWithParams = (url, params, all = true, noNull = false) => {
  if (_.isNull(params) || _.isUndefined(params)) {
    return url
  }

  let count = 0,
    query = ""
  for (let key in params) {
    if (all || params[key]) {
      query += count === 0 ? "" : "&"

      if (_.isNull(params[key]) && noNull) {
        query += key
        count++
      } else if (!_.isUndefined(params[key])) {
        query += key + "=" + encodeURIComponent(params[key])
        count++
      }
    }
  }

  return `${url}${query ? "?" : ""}${query}`
}

export const getThumbnail = (image, width = false, height = false) => {
  if (image) {
    let url = image.fullPath || image.path || image.toString() || ""
    if (url.startsWith("http")) {
      return url
    } else {
      if (url.lastIndexOf("/")) {
        url = url.substring(url.lastIndexOf("/") + 1)
      }
      url = `${process.env.REACT_APP_API_BASEURL}/static/${url}`
      if (width || height) {
        url += "?"
      }
      if (width) {
        url += `w=${width}`
      }
      if (width && height) {
        url += "&"
      }
      if (height) {
        url += `h=${height}`
      }
    }
    return url
  }

  return ""
}

export const nl2br = (value) => {
  if (value) {
    return value.replace(/(?:\r\n|\r|\n)/g, "<br/>")
  }
  return value
}

export const getFormattedValue = (
  value,
  type,
  defaultValue = "n/a",
  maxTextLength = null,
  unit = null,
  considerEmptyStringAsNull = true
) => {
  if (_.isNull(value) || _.isUndefined(value)) {
    return defaultValue
  }
  if (considerEmptyStringAsNull && value === "") {
    return defaultValue
  }

  switch (type) {
    case "string":
      return value
    case "text":
      const textValue = nl2br(value?.trim())
      return maxTextLength ? _.truncate(textValue, { length: maxTextLength }) : textValue
    case "decimal":
      return `${localize(parseFloat(value), {})}${unit ? ` ${unit}` : ""}`
    case "integer":
      return `${localize(parseInt(value, 10), {})}${unit ? ` ${unit}` : ""}`
    case "date":
      return localize(value, { dateFormat: "dates.standard" })
    case "daterange":
      const startDate = value?.startDate
        ? localize(value.startDate, { dateFormat: "dates.standard" })
        : defaultValue
      const endDate = value?.endDate
        ? localize(value.endDate, { dateFormat: "dates.standard" })
        : defaultValue
      return `${startDate} - ${endDate}`
    case "boolean":
      return value ? translate("common.yes") : translate("common.no")
    default:
      return value
  }
}

export const humanFileSize = (bytes, si) => {
  let thresh = si ? 1000 : 1024
  if (Math.abs(bytes) < thresh) {
    return bytes + " B"
  }
  let units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"]
  let u = -1
  do {
    bytes /= thresh
    ++u
  } while (Math.abs(bytes) >= thresh && u < units.length - 1)

  return bytes.toFixed(1) + " " + units[u]
}

export const formatDeviceType = (name) => {
  if (!name) {
    return ""
  }
  const parts = name.split("_")
  return parts.map((part) => _.upperFirst(_.trim(part))).join(" ")
}

export const dateDiffInDays = (a, b) => {
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate())

  return Math.floor((utc2 - utc1) / MS_PER_DAY)
}

export const slugify = (string) => {
  const a = "àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;"
  const b = "aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------"
  const p = new RegExp(a.split("").join("|"), "g")

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, "-and-") // Replace & with 'and'
    .replace(/[^\w-]+/g, "") // Remove all non-word characters
    .replace(/--+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, "") // Trim - from end of text
    .replace(/-/g, "_")
}

export const setPageTitle = (title, base = process.env.REACT_APP_CUSTOM_TITLE ?? "") => {
  document.title = `${title} - ${base}`
}

export const getDateWithoutTZ = (dtz, days = null) => {
  if (!dtz) {
    return null 
  }

  const date = new Date(dtz)
  const userTimezoneOffset = Math.abs(date.getTimezoneOffset() * 60000)
  const dwtz = new Date(date.getTime() - userTimezoneOffset)

  if (!_.isNil(days)) {
    dwtz.setDate(dwtz.getDate() + days)
  }

  return dwtz.toISOString()
}

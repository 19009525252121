import { useColorMode } from "@chakra-ui/react"
import _ from "lodash"
import * as Icons from "components/icons"

export const LogoSharemat = (props) => {
  const { colorMode } = useColorMode()
  // on récupère le bon logo en fonction de REACT_APP_CUSTOM_STYLES
  const Icon = Icons[`${_.upperFirst(process.env.REACT_APP_CUSTOM_STYLES)}Icon`]

  if (_.isNil(Icon)) {
    return null
  }

  return <Icon colorMode={colorMode} {...props} />
}

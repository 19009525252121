import { Flex, Image } from "@chakra-ui/react"
import { logoAtom, useAtom } from "store"
import { getThumbnail } from "utils"

export const Logo = (props) => {
  const [logo] = useAtom(logoAtom)

  if (!logo) {
    return null
  }

  const width = 200

  return (
    <Flex flexGrow={1} {...props}>
      <Image
        src={getThumbnail(logo, width)}
        boxSize={["60px", "70px", "80px"]}
        objectFit="contain"
        alt={logo.alt}
      />
    </Flex>
  )
}

import { useEffect } from "react"
import FileSaver from "file-saver"
import { useApiGetBlob } from "apis/sharemat"

export const useGetFile = (file, name, enabled = true) => {
  const {
    data,
    isLoading: loading,
    isSuccess,
    error,
  } = useApiGetBlob(file, {}, { staleTime: 0, cacheTime: 0, enabled: file && enabled })

  useEffect(() => {
    if (isSuccess && data && enabled) {
      FileSaver.saveAs(data, name)
    }
  }, [isSuccess, loading, enabled, data, name])

  return {
    loading,
    error,
  }
}

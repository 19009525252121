import { translate } from "react-i18nify"
import { Flex, Heading, Image, Stack } from "@chakra-ui/react"
import { DisplayValue } from "components"
import { getThumbnail } from "utils"

export const EquipmentIdentification = ({ equipment }) => {
  const brand = equipment.referentialBrand?.name ?? equipment.otherBrand
  const model = equipment.referentialModel?.name ?? equipment.otherModel

  return (
    <Stack>
      <Heading as="h1" mb={5} size="md">
        {equipment.name}
      </Heading>
      <Flex flexWrap={["wrap", "nowrap"]}>
        {equipment.mainPicture && (
          <Image
            src={getThumbnail(equipment.mainPicture.file, 1024)}
            mr={[0, 5]}
            mb={[5, 0]}
            boxSize={["100%", "50%"]}
            objectFit="contain"
            alt={equipment.name}
          />
        )}
        <Stack>
          <Heading as="h2" size="sm">
            {brand}
            {brand && model && " - "}
            {model}
          </Heading>
          <Stack>
            <DisplayValue
              entity={equipment}
              path="matriculation"
              type="string"
              label={translate("equipment.matriculation")}
            />
            <DisplayValue
              entity={equipment}
              path="serialNumber"
              type="string"
              label={translate("equipment.serial_number")}
            />
            <DisplayValue
              entity={equipment}
              path="currentDigitalTwinOwner.ownerReference"
              type="string"
              label={translate("equipment.owner_reference")}
            />
          </Stack>
        </Stack>
      </Flex>
    </Stack>
  )
}

import { localize, Translate } from "react-i18nify"
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  ListItem,
  UnorderedList,
} from "@chakra-ui/react"
import { DisplayValue } from "components"
import { formatDeviceType } from "utils"

export const EquipmentTelematic = ({ equipment }) => {
  if (!equipment.device) {
    return null
  }

  return (
    <AccordionItem borderTopWidth={2}>
      <AccordionButton>
        <Box flex="1" textAlign="left">
          <Heading size="md">
            <Translate value="equipment.telematic" />
          </Heading>
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={4}>
        <UnorderedList>
          <ListItem>
            <DisplayValue
              entity={equipment}
              path="device.type"
              label={<Translate value="device.type" />}
              format={(value) => formatDeviceType(value)}
            />
          </ListItem>
          <ListItem>
            <DisplayValue
              entity={equipment}
              path="device.reference"
              label={<Translate value="device.reference" />}
            />
          </ListItem>
          <ListItem>
            <DisplayValue
              entity={equipment}
              path="device.lastMessage"
              label={<Translate value="device.last_message" />}
              type="date"
            />
          </ListItem>
          <ListItem>
            {equipment.type === "rolling_equipment" ? (
              <DisplayValue
                entity={equipment}
                path="operatingDistance"
                label={<Translate value="device.operating_distance" />}
                type="integer"
                unit="km"
              />
            ) : (
              <DisplayValue
                entity={equipment}
                path="operatingDuration"
                label={<Translate value="device.operating_duration" />}
                format={(value) => `${value ? localize(value / 60) : 0} h`}
                type="integer"
              />
            )}
          </ListItem>
        </UnorderedList>
      </AccordionPanel>
    </AccordionItem>
  )
}

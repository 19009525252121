import { atom, useAtom } from "jotai"
import { getDefaultLang, getLocalStorageItem, setLocalStorageItem } from "utils"

// cf. https://github.com/pmndrs/jotai

export const atomWithLocalStorage = (key, initialValue, json = true) => {
  const getInitialValue = () => {
    const item = getLocalStorageItem(key)
    if (item !== null) {
      if (json) {
        return JSON.parse(item)
      } else {
        return item
      }
    }
    return initialValue
  }
  const baseAtom = atom(getInitialValue())
  const derivedAtom = atom(
    (get) => get(baseAtom),
    (get, set, update) => {
      const nextValue = typeof update === "function" ? update(get(baseAtom)) : update
      set(baseAtom, nextValue)
      setLocalStorageItem(key, json ? JSON.stringify(nextValue) : nextValue)
    }
  )
  return derivedAtom
}

export const langAtom = atomWithLocalStorage("lang", getDefaultLang(), false)
export const logoAtom = atomWithLocalStorage("logo", null, true)

export { useAtom }

import { extendTheme } from "@chakra-ui/react"
import { mode } from "@chakra-ui/theme-tools"

export const bia = extendTheme({
  styles: {
    global: (props) => ({
      body: {
        bg: mode("#fff", "#001224")(props),
        color: mode("#4a4a49", "white")(props),
      },
    }),
  },
  components: {
    Accordion: {
      parts: ["button", "icon"],
      baseStyle: {
        button: {
          _hover: {
            bg: "#7fc4ff",
          },
        },
        icon: {
          color: "#004587",
          fontSize: "2rem",
        },
      },
    },
    Button: {
      variants: {
        important: {
          color: "white",
          bg: "#004587",
          _hover: {
            bg: "#005eb8",
          },
        },
      },
    },
  },
})

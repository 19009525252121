import { localize, Translate } from "react-i18nify"
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
} from "@chakra-ui/react"
import _ from "lodash"
import { getDateWithoutTZ } from "utils"

const Availability = ({ availability }) => {
  if (availability.available && availability.until) {
    return (
      <>
        <Box w={3} h={3} mr={2} bg="green" borderRadius="100%" />
        <Translate
          value="equipment.available_until"
          date={localize(getDateWithoutTZ(availability.until), {
            dateFormat: "dates.standard",
          })}
        />
      </>
    )
  }

  if (!availability.available) {
    return (
      <>
        <Box w={3} h={3} mr={2} bg="red" borderRadius="100%" />
        <Translate
          value="equipment.unavailable_until"
          date={localize(getDateWithoutTZ(availability.until), {
            dateFormat: "dates.standard",
          })}
        />
      </>
    )
  }

  return (
    <>
      <Box w={3} h={3} mr={2} bg="green" borderRadius="100%" />
      <Translate value="equipment.available" />
    </>
  )
}

export const EquipmentAvailability = ({ equipment }) => {
  if (_.isNil(equipment?.availability)) {
    return null
  }

  return (
    <AccordionItem borderTopWidth={2}>
      <AccordionButton>
        <Box flex="1" textAlign="left">
          <Heading size="md">
            <Translate value="equipment.availability" />
          </Heading>
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={4}>
        <Flex align="center" mb={2}>
          <Availability availability={equipment.availability} />
        </Flex>
      </AccordionPanel>
    </AccordionItem>
  )
}

import { Translate } from "react-i18nify"
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
} from "@chakra-ui/react"
import _ from "lodash"
import { DownloadButton } from "components"

export const EquipmentDocuments = ({ equipment }) => {
  if (!equipment.documents || equipment.documents.length === 0) {
    return null
  }

  const documents = _.orderBy(equipment.documents, "file.createdAt", "desc")

  return (
    <AccordionItem borderTopWidth={2}>
      <AccordionButton>
        <Box flex="1" textAlign="left">
          <Heading size="md">
            <Translate value="equipment.documents" />
          </Heading>
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={4}>
        {documents.map((document, index) => (
          <DownloadButton key={index} document={document} name={document.name} />
        ))}
      </AccordionPanel>
    </AccordionItem>
  )
}

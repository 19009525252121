import React, { StrictMode } from "react"
import * as ReactDOM from "react-dom/client"
import { addLocales, I18n, setLocale, setTranslations } from "react-i18nify"
import { QueryClient, QueryClientProvider } from "react-query"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import reportWebVitals from "./reportWebVitals"
import * as serviceWorker from "./serviceWorker"
import { ColorModeScript } from "@chakra-ui/react"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import de from "date-fns/locale/de"
import en from "date-fns/locale/en-US"
import fr from "date-fns/locale/fr"
import nl from "date-fns/locale/nl"
import { translations } from "translations"
import { DefaultLayout } from "layouts"
import { Equipment, Error } from "pages"
import { getDefaultLang } from "utils"

const sentryDsn = process.env.REACT_APP_SENTRY_DSN

if (sentryDsn) {
  Sentry.init({
    dsn: sentryDsn,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

setTranslations(translations)
addLocales({ fr, en, nl, de })
setLocale(getDefaultLang())

// React Query configuration
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => false,
    },
  },
})

const container = document.getElementById("root")
const root = ReactDOM.createRoot(container)

root.render(
  <StrictMode>
    <BrowserRouter>
      <ColorModeScript />
      <QueryClientProvider client={queryClient}>
        <I18n
          render={() => (
            <DefaultLayout>
              <Routes>
                <Route path="/" element={<Error code="403" />} />
                <Route path="/404" element={<Error code="404" />} />
                <Route path="/:guid" element={<Equipment />} />

                <Route path="*" element={<Error code="404" />} />
              </Routes>
            </DefaultLayout>
          )}
        />
      </QueryClientProvider>
    </BrowserRouter>
  </StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import { translate } from "react-i18nify"
import { FaMoon, FaSun } from "react-icons/fa"
import { IconButton, useColorMode, useColorModeValue } from "@chakra-ui/react"

export const ColorModeSwitcher = (props) => {
  const { toggleColorMode } = useColorMode()
  const theme = useColorModeValue("dark", "light")
  const SwitchIcon = useColorModeValue(FaMoon, FaSun)

  return (
    <IconButton
      size="md"
      fontSize="lg"
      aria-label={translate("application.switch_theme", { theme: translate(`application.${theme}`) })}
      title={translate("application.switch_theme", { theme: translate(`application.${theme}`) })}
      variant="ghost"
      color="current"
      marginLeft="2"
      onClick={toggleColorMode}
      icon={<SwitchIcon />}
      {...props}
    />
  )
}

import { setLocale, Translate } from "react-i18nify"
import { Select } from "@chakra-ui/react"
import { langAtom, useAtom } from "store"
import { constants } from "utils"

export const LangSwitcher = () => {
  const [lang, setLang] = useAtom(langAtom)

  const onChange = (event) => {
    const newLang = event.target.value

    setLang(newLang)
    setLocale(newLang)
  }

  return (
    <Select minW="130" maxW="160" value={lang} onChange={onChange}>
      {constants.LANGS.map(l => (
        <option key={l} value={l}><Translate value={`locales.${l}`} /></option>
      ))}
    </Select>
  )
}

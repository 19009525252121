import { useQuery } from "react-query"
import { useNavigate } from "react-router-dom"
import { useToast } from "@chakra-ui/react"
import axios from "axios"
import { displayHydraError, getDefaultLang, getUrlWithParams } from "utils"

// configuration par défaut d'axios
export const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASEURL,
  headers: {
    "Content-Type": "application/json",
    "Accept-Language": getDefaultLang(),
  },
})

// hook générique pour les GET
export const useApiGet = (
  url,
  params = null,
  redirectIf404 = true,
  displayError = true,
  queryOptions = null,
  propagate = true
) => {
  const navigate = useNavigate()
  const toast = useToast()
  const urlWithParams = getUrlWithParams(url, params)

  return useQuery(
    [url, params],
    async () => {
      try {
        const response = await api.get(urlWithParams)

        return response.data
      } catch (error) {
        if (redirectIf404 && error?.response?.status === 404) {
          navigate("/404")
        }
        if (displayError && error?.response?.status !== 404) {
          displayHydraError(toast, error)
        }
        if (propagate) {
          throw error
        }
      }
    },
    queryOptions
  )
}

// hook générique pour les GET file
export const useApiGetBlob = (
  url,
  params = null,
  queryOptions = null,
  displayError = true,
  redirectIf404 = false,
  propagate = true
) => {
  const navigate = useNavigate()
  const toast = useToast()
  const urlWithParams = getUrlWithParams(url, params, true, false, true)

  return useQuery(
    [url, params],
    async () => {
      try {
        const response = await api.get(urlWithParams, {
          responseType: "blob",
        })

        return response.data
      } catch (error) {
        if (redirectIf404 && error?.response?.status === 404) {
          navigate("/404")
        }
        if (displayError && error?.response?.status !== 404) {
          displayHydraError(toast, error)
        }
        if (propagate) {
          throw error
        }
      }
    },
    queryOptions
  )
}
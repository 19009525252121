import { Translate } from "react-i18nify"
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
} from "@chakra-ui/react"
import { InterventionStatus } from "components"

export const EquipmentInterventions = ({ equipment }) => {
  const maintenanceConfigurations = equipment.publicMaintenanceConfigurations

  if (!maintenanceConfigurations || maintenanceConfigurations.length === 0) {
    return null
  }

  return (
    <AccordionItem borderTopWidth={2}>
      <AccordionButton>
        <Box flex="1" textAlign="left">
          <Heading size="md">
            <Translate value="equipment.maintenance_book" />
          </Heading>
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={4}>
        {maintenanceConfigurations.map((configuration, index) => (
          <InterventionStatus key={index} equipment={equipment} configuration={configuration} />
        ))}
      </AccordionPanel>
    </AccordionItem>
  )
}

import { Translate } from "react-i18nify"
import { DisplayValue } from "./DisplayValue"
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  ListItem,
  UnorderedList,
} from "@chakra-ui/react"
import _ from "lodash"
import { getFormattedValue } from "utils"

export const EquipmentSpecs = ({ equipment }) => {
  const fields = [
    { key: "commissioningDate", type: "date" },
    { key: "manufactureDate", type: "date" },
    { key: "length", type: "decimal" },
    { key: "capacity", type: "integer" },
    { key: "co2", type: "integer" },
    { key: "energy", type: "string" },
    { key: "fiscalHorsepower", type: "integer" },
    { key: "height", type: "decimal" },
    { key: "noise", type: "integer" },
    { key: "options", type: "text" },
    { key: "power", type: "integer" },
    { key: "ptca", type: "integer" },
    { key: "transportMode", type: "string" },
    { key: "weight", type: "integer" },
    { key: "width", type: "decimal" },
  ]
  const specs = []

  fields.forEach((field) => {
    if (
      !_.isUndefined(equipment[field.key]) &&
      !_.isNull(equipment[field.key]) &&
      !_.isNull(getFormattedValue(equipment[field.key], field.type, null))
    ) {
      specs.push(field)
    }
  })

  if (specs.length === 0) {
    return null
  }

  return (
    <AccordionItem borderTopWidth={2}>
      <AccordionButton>
        <Box flex="1" textAlign="left">
          <Heading size="md">
            <Translate value="equipment.technical_specs" />
          </Heading>
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={4}>
        <UnorderedList>
          {specs.map((spec) => (
            <ListItem key={spec.key}>
              <DisplayValue
                entity={equipment}
                path={spec.key}
                label={<Translate value={`equipment.${_.snakeCase(spec.key)}`} />}
                type={spec.type}
                format={
                  spec.key === "energy"
                    ? (value) => <Translate value={`api.energy.${value}`} />
                    : null
                }
              />
            </ListItem>
          ))}
        </UnorderedList>
      </AccordionPanel>
    </AccordionItem>
  )
}

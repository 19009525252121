import { useEffect, useState } from "react"
import { translate } from "react-i18nify"
import { FaFileAlt, FaLink } from "react-icons/fa"
import { Box, Button, Icon, Spinner, Link, Wrap } from "@chakra-ui/react"
import { useGetFile } from "hooks"
import { humanFileSize, slugify } from "utils"

const DownloadAction = ({ file, name, startDownload, onLoad }) => {
  const { loading } = useGetFile(file, slugify(name), startDownload)

  useEffect(() => {
    onLoad(loading)
  }, [loading, onLoad])

  if (!startDownload) {
    return null
  }

  return <>{loading && <Spinner size="sm" mr={3} />}</>
}

export const DownloadButton = ({ document, name, displaySize = true }) => {
  const file = document.file
  const defaultName = name || file.filename || file.name
  const [startDownload, setStartDownload] = useState(false)
  const isExternal = document?.file.filename === "external.file"

  const onLoad = (loading) => {
    if (!loading) {
      setStartDownload(false)
    }
  }

  return (
    <Wrap align="center" mb={2}>
      <Icon as={isExternal ? FaLink : FaFileAlt} mr={3} />
      {!isExternal && (
        <Button
          onClick={() => setStartDownload(true)}
          disabled={startDownload || !file?.size}
          variant="link"
          title={translate("common.download")}
        >
          <Box mr={3}>{defaultName}</Box>
          <DownloadAction
            file={document.publicFullPath}
            name={defaultName}
            startDownload={startDownload}
            onLoad={onLoad}
          />
        </Button>
      )}
      {isExternal && (
        <Link
          href={document.file.fullPath}
          isExternal
          title={translate("common.open_external_link")}
        >
          <Button disabled={startDownload || !file?.size} variant="link">
            {defaultName}
          </Button>
        </Link>
      )}
      {displaySize && file?.size && !isExternal && (
        <Box dangerouslySetInnerHTML={{ __html: `(${humanFileSize(file.size)})` }} />
      )}
    </Wrap>
  )
}

import { Icon } from "@chakra-ui/react"

export const BiaIcon = ({
  colorMode = "light",
  w = ["80px", "110px", "140px"],
  h = ["40px", "55px", "70px"],
  ...rest
}) => {
  const colors = { light: ["#18468b", "#444141"], dark: ["#fff", "#fff"] }

  return (
    <Icon viewBox="0 0 676.133 353.507" w={w} h={h} {...rest}>
      <path
        d="M2927.63 2043.23c-107.89 107.89-244.98 62.2-323.03 140.26-69.09 69.07-79.11 222.01-135.02 310.86-1.89 3.04-3.96 5.95-6.05 8.9-5.66 8.04-11.59 15.67-18.23 22.31-67.3 67.3-176.41 67.3-243.71 0-67.29-67.3-67.3-176.4 0-243.7 107.89-107.9 256.5-73.72 334.57-151.79 68.69-68.69 70.04-211.44 122.98-298.36 2.18-3.58 4.56-7.01 7.04-10.46 5.44-7.76 11.19-15.17 17.74-21.72 67.3-67.3 176.41-67.3 243.71 0 67.3 67.29 67.3 176.4 0 243.7M3450.3 2118.55c-107.89 107.89-244.97 62.19-323.04 140.25-69.07 69.08-79.11 222.01-135.01 310.86-1.89 3.05-3.96 5.96-6.05 8.9-5.66 8.04-11.59 15.67-18.23 22.32-67.3 67.3-176.41 67.3-243.71 0-67.3-67.3-67.3-176.41-.01-243.71 107.89-107.89 256.52-73.73 334.57-151.78 68.7-68.69 70.04-211.44 122.98-298.36 2.19-3.59 4.57-7.03 7.04-10.47 5.44-7.76 11.21-15.17 17.75-21.72 67.3-67.3 176.41-67.3 243.71 0 67.29 67.3 67.29 176.41 0 243.71"
        style={{ fill: colors[colorMode][0], fillOpacity: 1, fillRule: "nonzero", stroke: "none" }}
        transform="matrix(.13333 0 0 -.13333 0 353.507)"
      />
      <path
        d="M2006.61 1585.51c-66.14 66.14-78.08 209.26-128.05 299.31-5.95 11.52-13.38 22.45-22.12 32.66-2.94 3.52-5.95 6.93-9.12 10.1-67.3 67.3-176.41 67.3-243.71 0-67.3-67.29-67.3-176.4 0-243.7 107.89-107.89 256.5-73.73 334.57-151.79 63.97-63.97 69.55-192.16 112.76-279.69 8.33-18.41 19.87-35.72 35-50.86 67.3-67.29 176.41-67.29 243.71 0 67.29 67.3 67.29 176.41 0 243.71-107.89 107.89-244.98 62.2-323.04 140.26M3284.08 1653.79c-67.3 67.3-176.41 67.3-243.7 0-67.3-67.3-67.3-176.4 0-243.7 67.29-67.3 176.4-67.3 243.7 0 67.3 67.3 67.3 176.4 0 243.7M2035.19 2093.98c67.3 67.31 67.3 176.41 0 243.71-67.3 67.29-176.41 67.29-243.7 0-67.3-67.3-67.3-176.4 0-243.71 67.29-67.29 176.4-67.29 243.7 0M2546.77 1643.33c-66.14 66.14-78.08 209.27-128.05 299.31-5.95 11.52-13.39 22.45-22.12 32.67-2.94 3.51-5.95 6.92-9.13 10.1-67.29 67.3-176.4 67.3-243.7 0-67.3-67.3-67.3-176.41 0-243.71 107.89-107.89 256.5-73.72 334.56-151.78 63.98-63.98 69.55-192.16 112.77-279.7 8.33-18.4 19.86-35.72 35-50.85 67.29-67.3 176.41-67.3 243.7 0 67.31 67.3 67.31 176.41 0 243.71-107.89 107.88-244.97 62.19-323.03 140.25M186.422 0C119.656 0 49.43 14.96 0 37.832l21.676 102.066c52.902-16.718 106.66-28.148 166.48-28.148 66.766 0 79.774 13.191 79.774 62.449 0 52.801-2.606 58.961-97.114 80.071-139.593 30.8-160.414 58.949-160.414 185.64 0 117.02 40.762 163.649 199.434 163.649 50.285 0 110.98-5.278 175.141-19.348L371.969 473.34c-65.895 11.449-108.379 16.719-160.399 16.719-58.101 0-71.101-10.559-71.101-53.668 0-56.309 2.594-57.18 93.633-79.172 156.074-37.84 163.886-68.637 163.886-183.887C397.988 57.192 360.703 0 186.422 0M614.785 334.34 606.113 8.8H480.387l24.289 585.981h130.051l138.742-373.062 138.719 373.062h130.052l24.29-585.98H940.801l-8.664 325.539L831.555 84.46H715.367L614.785 334.34M2493.85 477.75V8.8h-130.06v468.95h-155.2v117.031h440.46V477.75h-155.2"
        style={{ fill: colors[colorMode][1], fillOpacity: 1, fillRule: "nonzero", stroke: "none" }}
        transform="matrix(.13333 0 0 -.13333 0 353.507)"
      />
      <path
        d="M2919.63 8.8v585.981h328.62v-46.652h-277.46V322.02h258.38v-46.629h-258.38V8.801h-51.16M3413.88 55.441H3674V8.801h-311.29v585.98h51.17V55.441M4842.05 548.129h-177.76v46.652h406.66v-46.652H4893.2V8.801h-51.15v539.328"
        style={{ fill: colors[colorMode][0], fillOpacity: 1, fillRule: "nonzero", stroke: "none" }}
        transform="matrix(.13333 0 0 -.13333 0 353.507)"
      />
      <path
        d="M1476.7 594.781h-173.41L1127.27 8.801h135.27l11.79 43.23 49.01 181.129h-.11l66.75 245.469 65.91-245.469h-93.87l-28.58-109.98h153.66L1518.32 8.8h135.26L1476.7 594.782M2067.21 209.398c58.08 24.633 84.1 73.911 84.1 146.934v72.148c0 109.989-52.02 166.301-192.49 166.301h-240.17V8.801h130.06v91.32l-.48-.133.98 205.313h-.5V477.75h103.17c52.9 0 69.37-17.602 69.37-61.59v-46.629c0-43.992-13.88-64.23-69.37-64.23h-65.41l.6-114.371h37.94L2052.47 8.8h154.34l-139.6 200.598"
        style={{ fill: colors[colorMode][1], fillOpacity: 1, fillRule: "nonzero", stroke: "none" }}
        transform="matrix(.13333 0 0 -.13333 0 353.507)"
      />
      <path
        d="M4097.15 330.82h-217.57l.24-46.64h217.33v46.64"
        style={{ fill: colors[colorMode][0], fillOpacity: 1, fillRule: "nonzero", stroke: "none" }}
        transform="matrix(.13333 0 0 -.13333 0 353.507)"
      />
      <path
        d="M3842.24 55.441v57.668l-.85-.23 1.05 217.941h-.2v217.309h274v46.652h-325.16V8.801h326.02v46.64h-274.86M4307.03 55.441v62.61l-.82-.219 1.01 212.988h-.19v217.309h273.99v46.652h-325.15V8.801h326.01v46.64h-274.85"
        style={{ fill: colors[colorMode][0], fillOpacity: 1, fillRule: "nonzero", stroke: "none" }}
        transform="matrix(.13333 0 0 -.13333 0 353.507)"
      />
      <path
        d="M4561.94 330.82h-217.53l.24-46.64h217.29v46.64"
        style={{ fill: colors[colorMode][0], fillOpacity: 1, fillRule: "nonzero", stroke: "none" }}
        transform="matrix(.13333 0 0 -.13333 0 353.507)"
      />
    </Icon>
  )
}

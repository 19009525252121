import { Flex } from "@chakra-ui/react"
import { LegalNotice } from "layouts"
import { ColorModeSwitcher, LangSwitcher } from "components"

export const Footer = () => (
  <Flex
    width="100%"
    flexWrap={["wrap", "wrap", "nowrap"]}
    justifyContent="space-between"
    alignItems="center"
    py="1rem"
  >
    <Flex mr={[0, 0, "2rem"]} mb={["1em", "1rem", 0]}>
      <LegalNotice />
    </Flex>
    <Flex w={["100%", "100%", "auto"]} justify="space-between">
      <LangSwitcher />
      <ColorModeSwitcher />
    </Flex>
  </Flex>
)

import { Translate } from "react-i18nify"
import { Flex, Heading, Stack, Text } from "@chakra-ui/react"

export const Error = ({ code = null }) => (
  <Flex flexGrow={1} justify="center" alignItems="center">
    <Stack align="center">
      {code && <Heading>{code}</Heading>}
      <Text fontSize="2xl" textAlign="center">
        {code === "403" && <Translate value="application.contact_us" />}
        {code === "404" && <Translate value="application.page_not_found" />}
      </Text>
    </Stack>
  </Flex>
)
